import React from 'react';
import './../Component/custom.css'
const TmaxCob = () => {
    return (
        <div className="t-series">
            <br></br>
            {/* <h2>TMAX COB Series</h2> */}
            <p style={{ textAlign: 'justify' }}>
                The TMAX COB is the top choice for HD screens, particularly in the P1.56 range. This series offers several unique advantages over traditional SMD screens.
            </p>
            <div className="headingpdf" style={{ width: "200px", borderBottom: "2px solid white" }}>
                <h2 >FEATURES</h2>
            </div>
            <br></br>
            <ul>
                <li>16:9 golden ratio panel, easily assembled 2k,4k screen.</li>
                <li> Ultra thin as 31.3(including the modules) and Ultra light, Plus full front installation& maintenance, can be mounted on the wall directly </li>
                <li>Extremely strong protect level for front side:
                    <br></br>
                    IP65, Anti-collision, Moistureproof, especially Anti-collision, which solves very significant problems for tiny pixel pitch.  </li>
                {/* <li>high contrast as 10000:1, more colorful and more detailed content  </li>
                <li>Optional: 
                    <ul>
                        <li> PSU/ Signal dual backup (by way of backup so that the screen will not stop working due to failure).  </li>
                        <li>Intelligent Module.</li>
                    </ul>
                </li>
                <li>Ambient temperature and humidity detection</li>
                <li>Localization of panels with power/case failure</li>
                <li>No need to load files, the modules inside automatically recognizes the rows and columns of the screen</li> */}
            </ul>
        </div>
    );
};

export default TmaxCob;
