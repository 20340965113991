import React from 'react';
import './../Component/custom.css'
const InpadMax = () => {
    return (
        <div className="t-series">
            <br></br>
            {/* <h2>T Series</h2> */}
            <p style={{ textAlign: 'justify' }}>
                The Inpad Max series is designed for indoor fixed installations, offering a special size with a protective module shell. It is available in multiple sizes, including 500x500mm, 500x750mm, and 500x1000mm, with various pixel pitches such as P1.9, P2.6, P2.9, P3.1, P3.9, and P4.8.
            </p>
            <div className="headingpdf" style={{ width: "200px", borderBottom: "2px solid white" }}>
                <h1 >FEATURES</h1>
            </div>
            <br></br>
            <ul>
                <li>Multiple sizes: 500x500/ 500*750/ 500*1000mm with Pixel pitch: P1.9/ P2.6/ P2.9/ P3.1/ P3.9/ P4.8  </li>
                <li>Fully front&rear maintaince, fits more installation scenes</li>
                <li>Supported 45° edge-cutting, more creative shapes </li>

            </ul>
        </div>
    );
};

export default InpadMax;
