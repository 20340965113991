const indoor = [
    {
        category: "INDOOR",
        model: "FAB-T1.95",
        description: "T Series",
        seriesComId: 6,
        seriesImage: "T-series-e.png",
        pixelPitch: 1.95,
        ledType: "SMD1212",
        brightness: 1000,
        cabinetSizeWxh: "1000x500",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "512x256",
        cabinetResolutionPXW: 512,
        cabinetResolutionPXH: 256,
        moduleSize: "250x250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "128x128",
        moduleResolutoinW: 128,
        moduleResolutoinH: 128,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 10,
        maxPowerConsumption: 290,
        avgPowerConsumption: 80,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "90 degree design, Seamless right-angle installation.",
            point2: "Cube design, 500x500mm cabinets support a perfect cube shape!",
            point3: "Magnetic mounting convenient for installing and removing.",
            point4: "All hard connection with cable—free design, very stable!.",
            point5: "Easy and fully front maintenance!",

        }
    },
    {
        category: "INDOOR",
        model: "FAB-T1.95",
        description: "T Series",
        seriesComId: 6,
        seriesImage: "T-series-e.png",
        pixelPitch: 1.95,
        ledType: "SMD1212",
        brightness: 3000,
        cabinetSizeWxh: "1000x500",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "512x256",
        cabinetResolutionPXW: 512,
        cabinetResolutionPXH: 256,
        moduleSize: "250x250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "128x128",
        moduleResolutoinW: 128,
        moduleResolutoinH: 128,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 10,
        maxPowerConsumption: 290,
        avgPowerConsumption: 80,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "90 degree design, Seamless right-angle installation.",
            point2: "Cube design, 500x500mm cabinets support a perfect cube shape!",
            point3: "Magnetic mounting convenient for installing and removing.",
            point4: "All hard connection with cable—free design, very stable!.",
            point5: "Easy and fully front maintenance!",

        }
    },
    {
        category: "INDOOR",
        model: "FAB-T2.5",
        description: "T Series",
        seriesComId: 6,
        seriesImage: "T-series-e.png",
        pixelPitch: 2.5,
        ledType: "SMD1515",
        brightness: 1000,
        cabinetSizeWxh: "1000x500",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "400x200",
        cabinetResolutionPXW: 400,
        cabinetResolutionPXH: 200,
        moduleSize: "250x250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "100x100",
        moduleResolutoinW: 100,
        moduleResolutoinH: 100,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 10,
        maxPowerConsumption: 290,
        avgPowerConsumption: 90,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "90 degree design, Seamless right-angle installation.",
            point2: "Cube design, 500x500mm cabinets support a perfect cube shape!",
            point3: "Magnetic mounting convenient for installing and removing.",
            point4: "All hard connection with cable—free design, very stable!.",
            point5: "Easy and fully front maintenance!",

        }
    },
    {
        category: "INDOOR",
        model: "FAB-T2.6",
        description: "T Series",
        seriesComId: 6,
        seriesImage: "T-series-e.png",
        pixelPitch: 2.6,
        ledType: "SMD1515",
        brightness: 1000,
        cabinetSizeWxh: "1000x500",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "384x192",
        cabinetResolutionPXW: 384,
        cabinetResolutionPXH: 192,
        moduleSize: "250x250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "96x96",
        moduleResolutoinW: 96,
        moduleResolutoinH: 96,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 10,
        maxPowerConsumption: 290,
        avgPowerConsumption: 90,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "90 degree design, Seamless right-angle installation.",
            point2: "Cube design, 500x500mm cabinets support a perfect cube shape!",
            point3: "Magnetic mounting convenient for installing and removing.",
            point4: "All hard connection with cable—free design, very stable!.",
            point5: "Easy and fully front maintenance!",

        }
    },
    {
        category: "INDOOR",
        model: "FAB-T2.6",
        description: "T Series",
        seriesComId: 6,
        seriesImage: "T-series-e.png",
        pixelPitch: 2.6,
        ledType: "SMD1515",
        brightness: 3000,
        cabinetSizeWxh: "1000x500",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "384x192",
        cabinetResolutionPXW: 384,
        cabinetResolutionPXH: 192,
        moduleSize: "250x250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "96x96",
        moduleResolutoinW: 96,
        moduleResolutoinH: 96,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 10,
        maxPowerConsumption: 290,
        avgPowerConsumption: 90,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "90 degree design, Seamless right-angle installation.",
            point2: "Cube design, 500x500mm cabinets support a perfect cube shape!",
            point3: "Magnetic mounting convenient for installing and removing.",
            point4: "All hard connection with cable—free design, very stable!.",
            point5: "Easy and fully front maintenance!",

        }
    },
    {
        category: "INDOOR",
        model: "FAB-T3.9",
        description: "T Series",
        seriesComId: 6,
        seriesImage: "T-series-e.png",
        pixelPitch: 3.91,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "1000x500",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 500,
        cabinetResolutionPX: "256x128",
        cabinetResolutionPXW: 256,
        cabinetResolutionPXH: 128,
        moduleSize: "250x250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "64x64",
        moduleResolutoinW: 64,
        moduleResolutoinH: 64,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 10,
        maxPowerConsumption: 225,
        avgPowerConsumption: 75,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 160° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "90 degree design, Seamless right-angle installation.",
            point2: "Cube design, 500x500mm cabinets support a perfect cube shape!",
            point3: "Magnetic mounting convenient for installing and removing.",
            point4: "All hard connection with cable—free design, very stable!.",
            point5: "Easy and fully front maintenance!",

        }
    },  
    {
        category: "INDOOR",
        model: "FAB-TM1.5",
        description: "TMAX SERIES",
        seriesComId: 0,
        seriesImage: "T-max_e.png",
        pixelPitch: 1.5,
        ledType: "SMD1010",
        brightness: 600,
        cabinetSizeWxh: "1000X562.5",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 562.5,
        cabinetResolutionPX: "640X360",
        cabinetResolutionPXW: 640,
        cabinetResolutionPXH: 360,
        moduleSize: "250x281.25",
        moduleSizeW: 250,
        moduleSizeH: 281.25,
        moduleResolutoin: "160x180",
        moduleResolutoinW: 160,
        moduleResolutoinH: 180,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 11.7,
        maxPowerConsumption: 281,
        avgPowerConsumption: 112,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "'-20-+40",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }


    },
    {
        category: "INDOOR",
        model: "FAB-TM1.9",
        description: "TMAX SERIES",
        seriesComId: 0,
        seriesImage: "T-max_e.png",
        pixelPitch: 1.9,
        ledType: "SMD1212",
        brightness: 1000,
        cabinetSizeWxh: "1000X562.5",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 562.5,
        cabinetResolutionPX: "512x288",
        cabinetResolutionPXW: 512,
        cabinetResolutionPXH: 288,
        moduleSize: "250x281.25",
        moduleSizeW: 250,
        moduleSizeH: 281.25,
        moduleResolutoin: "128x144",
        moduleResolutoinW: 128,
        moduleResolutoinH: 144,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 11.7,
        maxPowerConsumption: 225,
        avgPowerConsumption: 90,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-TM2.6",
        description: "TMAX SERIES",
        seriesComId: 0,
        seriesImage: "T-max_e.png",
        pixelPitch: 2.6,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "1000X562.5",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 562.5,
        cabinetResolutionPX: "384x216",
        cabinetResolutionPXW: 384,
        cabinetResolutionPXH: 216,
        moduleSize: "250x281.25",
        moduleSizeW: 250,
        moduleSizeH: 281.25,
        moduleResolutoin: "96x108",
        moduleResolutoinW: 96,
        moduleResolutoinH: 108,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 11.7,
        maxPowerConsumption: 230,
        avgPowerConsumption: 94,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-TM3.9",
        description: "TMAX SERIES",
        seriesComId: 0,
        seriesImage: "T-max_e.png",
        pixelPitch: 3.9,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "1000X562.5",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 562.5,
        cabinetResolutionPX: "256x144",
        cabinetResolutionPXW: 256,
        cabinetResolutionPXH: 144,
        moduleSize: "250x281.25",
        moduleSizeW: 250,
        moduleSizeH: 281.25,
        moduleResolutoin: "64x72",
        moduleResolutoinW: 64,
        moduleResolutoinH: 72,
        moduleConfig: "4x2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 11.7,
        maxPowerConsumption: 236,
        avgPowerConsumption: 95,
        refreshmentHz: 3840,
        contrastRatio: "8000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-TMC0.7",
        description: "TMAX COB SERIES",
        seriesComId: 1,
        seriesImage: "TMAXCOB_e.png",
        pixelPitch: 0.78125,
        ledType: "COB",
        brightness: 1000,
        cabinetSizeWxh: "600X337.5",
        cabinetSizeWxhW: 600,
        cabinetSizeWxhH: 337.5,
        cabinetResolutionPX: "768X432",
        cabinetResolutionPXW: 768,
        cabinetResolutionPXH: 432,
        moduleSize: "150X168.75",
        moduleSizeW: 150,
        moduleSizeH: 168.75,
        moduleResolutoin: "192X216",
        moduleResolutoinW: 192,
        moduleResolutoinH: 216,
        moduleConfig: "4X2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 4.38,
        maxPowerConsumption: 90,
        avgPowerConsumption: 22,
        refreshmentHz: "1920-3840",
        contrastRatio: "10000:1",
        viewingAngleHv: "165° , 165° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-TMC0.9",
        description: "TMAX COB SERIES",
        seriesComId: 1,
        seriesImage: "TMAXCOB_e.png",
        pixelPitch: 0.9375,
        ledType: "COB",
        brightness: 1000,
        cabinetSizeWxh: "600X337.5",
        cabinetSizeWxhW: 600,
        cabinetSizeWxhH: 337.5,
        cabinetResolutionPX: "640X360",
        cabinetResolutionPXW: 640,
        cabinetResolutionPXH: 360,
        moduleSize: "150X168.75",
        moduleSizeW: 150,
        moduleSizeH: 168.75,
        moduleResolutoin: "160X180",
        moduleResolutoinW: 160,
        moduleResolutoinH: 180,
        moduleConfig: "4X2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 4.38,
        maxPowerConsumption: 80,
        avgPowerConsumption: 18,
        refreshmentHz: "1920-3840",
        contrastRatio: "10000:1",
        viewingAngleHv: "165° , 165° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-TMC1.2",
        description: "TMAX COB SERIES",
        seriesComId: 1,
        seriesImage: "TMAXCOB_e.png",
        pixelPitch: 1.25,
        ledType: "COB",
        brightness: 1000,
        cabinetSizeWxh: "600X337.5",
        cabinetSizeWxhW: 600,
        cabinetSizeWxhH: 337.5,
        cabinetResolutionPX: "480X270",
        cabinetResolutionPXW: 480,
        cabinetResolutionPXH: 270,
        moduleSize: "150X168.75",
        moduleSizeW: 150,
        moduleSizeH: 168.75,
        moduleResolutoin: "120X135",
        moduleResolutoinW: 120,
        moduleResolutoinH: 135,
        moduleConfig: "4X2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 4.38,
        maxPowerConsumption: 80,
        avgPowerConsumption: 18,
        refreshmentHz: "1920-3840",
        contrastRatio: "10000:1",
        viewingAngleHv: "165° , 165° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-TMC1.5",
        description: "TMAX COB SERIES",
        seriesComId: 1,
        seriesImage: "TMAXCOB_e.png",
        pixelPitch: 1.56,
        ledType: "COB",
        brightness: 800,
        cabinetSizeWxh: "600X337.5",
        cabinetSizeWxhW: 600,
        cabinetSizeWxhH: 337.5,
        cabinetResolutionPX: "384X216",
        cabinetResolutionPXW: 384,
        cabinetResolutionPXH: 216,
        moduleSize: "150X168.75",
        moduleSizeW: 150,
        moduleSizeH: 168.75,
        moduleResolutoin: "96X108",
        moduleResolutoinW: 96,
        moduleResolutoinH: 108,
        moduleConfig: "4X2",
        moduleConfigW: 4,
        moduleConfigH: 2,
        weightCabinetKG: 4.38,
        maxPowerConsumption: 106,
        avgPowerConsumption: 24,
        refreshmentHz: "1920-3840",
        contrastRatio: "10000:1",
        viewingAngleHv: "165° , 165° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+50",
        operatingHumidity: "10%~95%",
        ledLifetimeH: "100000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "16:9 Golden Ration design, 45inch cabinet.",
            point2: "28.5mm thickness.",
            point3: "Support power and signal redundancy.",
            point4: "Universal HUB for all pixel pitch.",
            point5: "Hard connection design for modules and power supply."
        }
    },
    {
        category: "INDOOR",
        model: "FAB-IPM1.95",
        description: "INPAD MAX SERIES",
        seriesComId: 2,
        seriesImage: "inpadMAX-500X1000_e.png",
        pixelPitch: 1.95,
        ledType: "SMD1212",
        brightness: 1000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "256X512",
        cabinetResolutionPXW: 256,
        cabinetResolutionPXH: 512,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "128X128",
        moduleResolutoinW: 128,
        moduleResolutoinH: 128,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 5.85,
        maxPowerConsumption: 280,
        avgPowerConsumption: 100,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Universal HUB design for all pixel pitch.",
            point2: "Hard connection design for modules.",
            point3: "Optional for high brightness 3,500nits.",
            point4: "Fast lock system for up&down, left&right.",
            point5: "Flexible cabinet size for more applications.",
        }
    },
    {
        category: "INDOOR",
        model: "FAB-IPM2.6",
        description: "INPAD MAX SERIES",
        seriesComId: 2,
        seriesImage: "inpadMAX-500X1000_e.png",
        pixelPitch: 2.6,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "192X384",
        cabinetResolutionPXW: 192,
        cabinetResolutionPXH: 384,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "96X96",
        moduleResolutoinW: 96,
        moduleResolutoinH: 96,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 10.25,
        maxPowerConsumption: 220,
        avgPowerConsumption: 75,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Universal HUB design for all pixel pitch.",
            point2: "Hard connection design for modules.",
            point3: "Optional for high brightness 3,500nits.",
            point4: "Fast lock system for up&down, left&right.",
            point5: "Flexible cabinet size for more applications.",
        }
    },
    {
        category: "INDOOR",
        model: "FAB-IPM2.9",
        description: "INPAD MAX SERIES",
        seriesComId: 2,
        seriesImage: "inpadMAX-500X1000_e.png",
        pixelPitch: 2.9,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "168X336",
        cabinetResolutionPXW: 168,
        cabinetResolutionPXH: 336,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "84X84",
        moduleResolutoinW: 84,
        moduleResolutoinH: 84,
        moduleConfig: "2X2",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 11.25,
        maxPowerConsumption: 220,
        avgPowerConsumption: 75,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Universal HUB design for all pixel pitch.",
            point2: "Hard connection design for modules.",
            point3: "Optional for high brightness 3,500nits.",
            point4: "Fast lock system for up&down, left&right.",
            point5: "Flexible cabinet size for more applications.",
        }
    },
    {
        category: "INDOOR",
        model: "FAB-IPM3.9",
        description: "INPAD MAX SERIES",
        seriesComId: 2,
        seriesImage: "inpadMAX-500X1000_e.png",
        pixelPitch: 3.9,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "128X256",
        cabinetResolutionPXW: 128,
        cabinetResolutionPXH: 256,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "64X64",
        moduleResolutoinW: 64,
        moduleResolutoinH: 64,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 12.25,
        maxPowerConsumption: 225,
        avgPowerConsumption: 80,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Universal HUB design for all pixel pitch.",
            point2: "Hard connection design for modules.",
            point3: "Optional for high brightness 3,500nits.",
            point4: "Fast lock system for up&down, left&right.",
            point5: "Flexible cabinet size for more applications.",
        }
    },
    {
        category: "INDOOR",
        model: "FAB-IPM4.8",
        description: "INPAD MAX SERIES",
        seriesComId: 2,
        seriesImage: "inpadMAX-500X1000_e.png",
        pixelPitch: 4.8,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "104X208",
        cabinetResolutionPXW: 104,
        cabinetResolutionPXH: 208,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "52X52",
        moduleResolutoinW: 52,
        moduleResolutoinH: 52,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.25,
        maxPowerConsumption: 225,
        avgPowerConsumption: 80,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Universal HUB design for all pixel pitch.",
            point2: "Hard connection design for modules.",
            point3: "Optional for high brightness 3,500nits.",
            point4: "Fast lock system for up&down, left&right.",
            point5: "Flexible cabinet size for more applications.",
        }
    },
    {
        category: "INDOOR",
        model: "FAB-IPM5.9",
        description: "INPAD MAX SERIES",
        seriesComId: 2,
        seriesImage: "inpadMAX-500X1000_e.png",
        pixelPitch: 5.9,
        ledType: "SMD2121",
        brightness: 1000,
        cabinetSizeWxh: "500X1000",
        cabinetSizeWxhW: 500,
        cabinetSizeWxhH: 1000,
        cabinetResolutionPX: "104X208",
        cabinetResolutionPXW: 84,
        cabinetResolutionPXH: 168,
        moduleSize: "250X250",
        moduleSizeW: 250,
        moduleSizeH: 250,
        moduleResolutoin: "42X42",
        moduleResolutoinW: 42,
        moduleResolutoinH: 42,
        moduleConfig: "2X4",
        moduleConfigW: 2,
        moduleConfigH: 4,
        weightCabinetKG: 13.25,
        maxPowerConsumption: 225,
        avgPowerConsumption: 80,
        refreshmentHz: 3840,
        contrastRatio: "7000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front/Rear",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Universal HUB design for all pixel pitch.",
            point2: "Hard connection design for modules.",
            point3: "Optional for high brightness 3,500nits.",
            point4: "Fast lock system for up&down, left&right.",
            point5: "Flexible cabinet size for more applications.",
        }
    },
    // {
    //     category: "INDOOR",
    //     model: "FAB-TP1.5",
    //     description: "THINPAD SERIES",
    //     seriesComId:4,
    //     pixelPitch: 1.5,
    //     ledType: "SMD1010",
    //     brightness: 600,
    //     cabinetSizeWxh: "500X281.25",
    //     cabinetSizeWxhW: 500,
    //     cabinetSizeWxhH: 281.25,
    //     cabinetResolutionPX: "320X180",
    //     cabinetResolutionPXW: 320,
    //     cabinetResolutionPXH: 180,
    //     moduleSize: "250X281.25",
    //     moduleSizeW: 250,
    //     moduleSizeH: 281.25,
    //     moduleResolutoin: "160X180",
    //     moduleResolutoinW: 160,
    //     moduleResolutoinH: 180,
    //     moduleConfig: "2X1",
    //     moduleConfigW: 2,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 3.1,
    //     maxPowerConsumption: 500,
    //     avgPowerConsumption: 200,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-TP1.9",
    //     description: "THINPAD SERIES",
    //     seriesComId:4,
    //     pixelPitch: 1.9,
    //     ledType: "SMD1212",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000X281.25",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 281.25,
    //     cabinetResolutionPX: "512X144",
    //     cabinetResolutionPXW: 512,
    //     cabinetResolutionPXH: 144,
    //     moduleSize: "250X281.25",
    //     moduleSizeW: 250,
    //     moduleSizeH: 281.25,
    //     moduleResolutoin: "128X144",
    //     moduleResolutoinW: 128,
    //     moduleResolutoinH: 144,
    //     moduleConfig: "4X1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 5.7,
    //     maxPowerConsumption: 400,
    //     avgPowerConsumption: 160,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-TP2.6",
    //     description: "THINPAD SERIES",
    //     seriesComId:4,
    //     pixelPitch: 2.6,
    //     ledType: "SMD2121",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000X281.25",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 281.25,
    //     cabinetResolutionPX: "384X108",
    //     cabinetResolutionPXW: 384,
    //     cabinetResolutionPXH: 108,
    //     moduleSize: "250X281.25",
    //     moduleSizeW: 250,
    //     moduleSizeH: 281.25,
    //     moduleResolutoin: "96X108",
    //     moduleResolutoinW: 96,
    //     moduleResolutoinH: 108,
    //     moduleConfig: "4X1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 5.7,
    //     maxPowerConsumption: 410,
    //     avgPowerConsumption: 170,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-TP3.9",
    //     description: "THINPAD SERIES",
    //     seriesComId:4,
    //     pixelPitch: 3.9,
    //     ledType: "SMD2121",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000X281.25",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 281.25,
    //     cabinetResolutionPX: "256X72",
    //     cabinetResolutionPXW: 256,
    //     cabinetResolutionPXH: 72,
    //     moduleSize: "250X281.25",
    //     moduleSizeW: 250,
    //     moduleSizeH: 281.25,
    //     moduleResolutoin: "64X72",
    //     moduleResolutoinW: 64,
    //     moduleResolutoinH: 72,
    //     moduleConfig: "4X1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 5.7,
    //     maxPowerConsumption: 420,
    //     avgPowerConsumption: 170,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-IP1.9",
    //     description: "INPAD SERIES",
    //     seriesComId:5,
    //     pixelPitch: 1.9,
    //     ledType: "SMD1515",
    //     brightness: 800,
    //     cabinetSizeWxh: "1000x250",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 250,
    //     cabinetResolutionPX: "512x128",
    //     cabinetResolutionPXW: 512,
    //     cabinetResolutionPXH: 128,
    //     moduleSize: "250x250",
    //     moduleSizeW: 250,
    //     moduleSizeH: 250,
    //     moduleResolutoin: "128x128",
    //     moduleResolutoinW: 128,
    //     moduleResolutoinH: 128,
    //     moduleConfig: "4x1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 6,
    //     maxPowerConsumption: 400,
    //     avgPowerConsumption: 160,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-IP2.6",
    //     description: "INPAD SERIES",
    //     seriesComId:5,
    //     pixelPitch: 2.6,
    //     ledType: "SMD2121",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000x250",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 250,
    //     cabinetResolutionPX: "384x96",
    //     cabinetResolutionPXW: 384,
    //     cabinetResolutionPXH: 96,
    //     moduleSize: "250x250",
    //     moduleSizeW: 250,
    //     moduleSizeH: 250,
    //     moduleResolutoin: "96x96",
    //     moduleResolutoinW: 96,
    //     moduleResolutoinH: 96,
    //     moduleConfig: "4x1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 6,
    //     maxPowerConsumption: 420,
    //     avgPowerConsumption: 1,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-IP3.1",
    //     description: "INPAD SERIES",
    //     seriesComId:5,
    //     pixelPitch: 3.1,
    //     ledType: "SMD2121",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000x250",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 250,
    //     cabinetResolutionPX: "320x80",
    //     cabinetResolutionPXW: 320,
    //     cabinetResolutionPXH: 80,
    //     moduleSize: "250x250",
    //     moduleSizeW: 250,
    //     moduleSizeH: 250,
    //     moduleResolutoin: "80x80",
    //     moduleResolutoinW: 80,
    //     moduleResolutoinH: 80,
    //     moduleConfig: "4x1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 6,
    //     maxPowerConsumption: 400,
    //     avgPowerConsumption: 160,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-IP3.9",
    //     description: "INPAD SERIES",
    //     seriesComId:5,
    //     pixelPitch: 3.9,
    //     ledType: "SMD2121",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000x250",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 250,
    //     cabinetResolutionPX: "256x64",
    //     cabinetResolutionPXW: 256,
    //     cabinetResolutionPXH: 64,
    //     moduleSize: "250x250",
    //     moduleSizeW: 250,
    //     moduleSizeH: 250,
    //     moduleResolutoin: "64x64",
    //     moduleResolutoinW: 64,
    //     moduleResolutoinH: 64,
    //     moduleConfig: "4x1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 6,
    //     maxPowerConsumption: 420,
    //     avgPowerConsumption: 130,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "28.5mm thickness ultrathin panel design.",
    //         point2: "8:9 ration module to build 16:9 ration screen.",
    //         point3: "Totally front service panel.",
    //         point4: "Universal HUB for all pixel pitch.",
    //         point5: "Hard connection for modules and power supply.",
    //         point6: "MOM intelligent module design.",

    //     }
    // },
    // {
    //     category: "INDOOR",
    //     model: "FAB-IP4.8",
    //     description: "INPAD SERIES",
    //     seriesComId:5,
    //     pixelPitch: 4.8,
    //     ledType: "SMD2121",
    //     brightness: 1000,
    //     cabinetSizeWxh: "1000x250",
    //     cabinetSizeWxhW: 1000,
    //     cabinetSizeWxhH: 250,
    //     cabinetResolutionPX: "208x52",
    //     cabinetResolutionPXW: 208,
    //     cabinetResolutionPXH: 52,
    //     moduleSize: "250x250",
    //     moduleSizeW: 250,
    //     moduleSizeH: 250,
    //     moduleResolutoin: "52x52",
    //     moduleResolutoinW: 52,
    //     moduleResolutoinH: 52,
    //     moduleConfig: "4x1",
    //     moduleConfigW: 4,
    //     moduleConfigH: 1,
    //     weightCabinetKG: 6,
    //     maxPowerConsumption: 420,
    //     avgPowerConsumption: 170,
    //     refreshmentHz: 3840,
    //     contrastRatio: "10000:1",
    //     viewingAngleHv: "160° , 160° ",
    //     workingVoltage: "100V ~ 240V 50~60Hz",
    //     operatingTempC: "-20~+40",
    //     operatingHumidity: "10%~95%",
    //     ledLifetimeH: "100000",
    //     maintenanceType: "Front",
    //     warranty: "2 years",
    //     seriesDescription: "",
    //     featuresId: {
    //         point1: "Typical design for indoor fixed installation.",
    //         point2: "Universal HUB for all pixel pitch.",
    //         point3: "Full front service and wall—mounted directly.",
    //         point4: "Super flat panel surface with CNC technology.",
    //         point5: "MOM intelligent module for storage of data.",

    //     }
    // },

    {
        category: "INDOOR",
        model: "FAB-1.95",
        description: "MAGICPAD SERIES",
        seriesComId: 7,
        seriesImage: "Magicpad Series.png",
        pixelPitch: 1.95,
        ledType: "SMD1515",
        brightness: 800,
        cabinetSizeWxh: "1000X93.75",
        cabinetSizeWxhW: 1000,
        cabinetSizeWxhH: 93.75,
        cabinetResolutionPX: "512X48",
        cabinetResolutionPXW: 512,
        cabinetResolutionPXH: 48,
        moduleSize: "250X93.5",
        moduleSizeW: 250,
        moduleSizeH: 93.5,
        moduleResolutoin: "128X48",
        moduleResolutoinW: 128,
        moduleResolutoinH: 48,
        moduleConfig: "4X1",
        moduleConfigW: 4,
        moduleConfigH: 1,
        weightCabinetKG: 2.4,
        maxPowerConsumption: 40,
        avgPowerConsumption: 16,
        refreshmentHz: 1920,
        contrastRatio: "6000:1",
        viewingAngleHv: "160° , 140° ",
        workingVoltage: "100V ~ 240V 50~60Hz",
        operatingTempC: "-20~+40",
        operatingHumidity: "10%~90%",
        ledLifetimeH: "50000",
        maintenanceType: "Front",
        warranty: "2 years",
        seriesDescription: "",
        featuresId: {
            point1: "Module GOB Design",
            point2: "Wireless Connection",
            point3: "Totally Front Maintenance",
            point4: "Ultra light& Slim",
            point5: "Creative Seamless splicing",

        }
    }
]

export default indoor