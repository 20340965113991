import React from 'react';
import './../Component/custom.css'
const Tseries = () => {
    return (
        <div className="t-series">
            <br></br>
            {/* <h2>T Series</h2> */}
            <p style={{ textAlign: 'justify' }}>
                The T Series is a fixed indoor screen , renowned for its advanced features and exceptional performance.
            </p>
            <div className="headingpdf" style={{ width: "200px", borderBottom: "2px solid white" }}>
                <h1 >FEATURES</h1>
            </div>
            <br></br>
            <ul>
                <li>One of the thinnest& lightest Led screen at market, which is as thin as 31.3mm (including modules), as light weight as 19.5kg/sqm, and can mounted on the wall directly without the structure. </li>
                <li>All cabinet die-casting aluminum, more robust and better heat dissipation than iron cabinet. </li>
                <li>Optional pixel points:
                    <br></br>
                    P1.95/ P2.5/ P2.6/ 3.9; <br></br>Panel optional size, which can match together(mm): 1000X500/500X500/250X500; 1000X250/500X250/250X250 (mm) </li>
                <li>Support 45°cutting angle customization, 500*500mm box can be made into cube shape (6 sides) </li>

            </ul>
        </div>
    );
};

export default Tseries;
