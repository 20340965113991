import React, { useRef, useEffect } from "react";
import { useState } from "react";
import './custom.css'
import { green } from "@mui/material/colors";
import './styles.css';

import imgWall from './../images/wall.jpg'
function Indoorscreen({ setDataShare, dataShare, dataIdScreenSelect, idScreen, imageBgSelect, WHData, selectEnv,
  setColRom,
  colRom,
  setWidthCheck,
  widthCheck,
  setHeightCheck,
  heightCheck,
  paddingBox,
  setHeightCheckMain,
  setWHData

}) {
  // const dataVariable={

  // }

  // const [data,setData]=useState()
  const [tableBg, setTableBg] = useState(imageBgSelect);
  const [tableBgWall, setTableBgWall] = useState(imgWall);
  const [dataRows, setDataRows] = useState(dataShare);
  const [tdhight, setTdhight] = useState(0);
  const [tdwidth, setTdwidth] = useState(0);
  const [selectClassEnv, setSelectClassEnv] = useState("percent");
  const [selectClassBorder, setSelectClassBorder] = useState("");
  const [bgDivWallImage, setBgDivWallImage] = useState("")
  const [positionBox, setPositionBox] = useState("centered-div-table")
  const [boxHeight, setBoxHeight] = useState(0)
  const [boxHeightManage, setBoxHeightManage] = useState(100)
  const [centerTab, setCenterTab] = useState("0px")

  // const [selectEnvData, setSelectEnvData] = React.useState(dataIdScreenSelect);
  const tableRows = [];
  useEffect(() => {
    setDataRows(dataShare)
    // alert(selectClassEnv)
    // alert("ff")
    boxGetHeight()
    checkOverflowHeight()
    checkOverflow();
    // checkOverflowHeightMain();

    if (selectEnv == 1) {
      if (WHData.typeOutDoor == 0) {
        setSelectClassEnv("outDoor")
        setSelectClassBorder("")
        setBoxHeightManage(0)
        setBgDivWallImage("bgDivWallImage")

        setPositionBox("centered-div-table")
      } else {
        setSelectClassBorder("boderOutDoorRoof")
        setSelectClassEnv("s")
        setBoxHeightManage(300)
        setBgDivWallImage("")
        setPositionBox("centered-div-table-roof")
      }

    } else {

      setSelectClassEnv("percent")
      setPositionBox("centered-div-table")
      setBgDivWallImage("")

    }
    // setSelectEnvData(dataIdScreenSelect ? dataIdScreenSelect : "")

    setTdhight(dataIdScreenSelect ? dataIdScreenSelect.cabinetSizeWxhH / 10 : "")
    setTdwidth(dataIdScreenSelect ? dataIdScreenSelect.cabinetSizeWxhW / 10 : "")
    setTableBg(imageBgSelect)
    setTableStrData()
  }, [dataIdScreenSelect, imageBgSelect, selectEnv, WHData, dataShare])



  function setTableStrData() {
    // Creating rows
    // let index = (WHData.width * 100) / tdwidth;
    // index=Math.floor(index)
    // alert(Math.floor(index))

    for (let i = 0; i < dataShare.row; i++) {
      const tableCols = [];

      // Creating columns
      for (let j = 0; j < dataShare.col; j++) {
        tableCols.push(<td key={j} style={{ border: "2px solid black", height: `${tdhight - WHData.boxHeight}px`, minWidth: `${tdwidth - WHData.boxWidth}px` }}></td>);
      }

      // Pushing the row to the tableRows array
      tableRows.push(<tr key={i}>{tableCols}</tr>);
    }

    // setMessage("Screen size is cover.")
    // setOpen(true)
    return (

      <table id="container" className={`auto-margin ${positionBox}   ${selectClassBorder}`}

        style={{
          width: `100%`,
          // backgroundColor: "black",

        }}>
        {/* <div class="horizontal-line"></div> */}
        {/* marginBottom: `${WHData.groundHeight * 100}px` */}
        <tbody id="myTable" style={{}}>
          <div className="topLine" style={{ marginLeft: "10px" }}>

            <center>
              <span>{((dataIdScreenSelect?.cabinetSizeWxhW * dataShare.col) / 1000)}m</span>
            </center>


          </div>

          <div id="boxTotal" style={{ backgroundImage: `url(${tableBg})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundPosition: "center", marginLeft: "10px", marginBottom: `${WHData.groundHeight * 100}px` }}>
            {tableRows}
          </div>

        </tbody>
        <div className="rotated-fieldset" style={{ marginLeft: "4px", marginBottom: centerTab }}>

          <center>
            <span>{((dataIdScreenSelect?.cabinetSizeWxhH * dataShare.row / 1000))}m</span>
          </center>


        </div>
      </table>
    );
  }

  const checkOverflow = () => {
    const container = document.getElementById('container');
    const table = document.getElementById('myTable');

    if (table?.offsetHeight >= container?.clientHeight) {
      setHeightCheck(false)
      // alert('Cannot add more columns as the table width is 100% of the container width');
      setColRom({ ...colRom, "row": colRom.row - 1 })
      setDataShare(colRom)

    }


  };

  // const checkOverflowHeightMain = () => {
  //   const containerMain = document.getElementById('containerMain');
  //   const manageHeight = document.getElementById('manageHeight');

  //   if (manageHeight?.offsetHeight+boxHeightManage >= containerMain?.clientHeight) {
  //     // setHeightCheck(false)
  //     setHeightCheckMain(false)
  //     // alert('Cannot add more columns as the table width is 100% of the container width');
  //     setWHData({ ...WHData, "height": WHData.height - 1 })
  //     // setDataShare(colRom)

  //   }


  // };

  const checkOverflowHeight = () => {
    const container = document.getElementById('container');
    const table = document.getElementById('myTable');

    if (table?.offsetWidth >= container?.clientWidth) {
      setWidthCheck(false)
      // alert('Cannot add more columns as the table width is 100% of the container width');
      setColRom({ ...colRom, "col": colRom.col - 1 })
      setDataShare(colRom)

    }


  };


  const boxGetHeight = () => {
    const boxTotal = document.getElementById('boxTotal');
    //  alert((WHData.groundHeight*100)/2)
    if (selectEnv == 1 && WHData.typeOutDoor == 1) {
      setCenterTab(`${boxTotal?.clientHeight / 2}px`)
    } else {
      setCenterTab(`${(boxTotal?.clientHeight + ((WHData.groundHeight * 100) / 1)) / 2}px`)
    }

    setBoxHeight(boxTotal?.clientHeight)

  };

  return (
    <>
      <div className="container-fluid" id="containerMain" style={{ height: "100%" }}>
        <div className="row">
          <div className="col-md-12">

            <div class={`${selectClassEnv}  table-container bg-white`}  >
              <div className="container-badge">
                {dataIdScreenSelect ? <>
                  <span className="badge left" style={{ marginTop: "10px", marginLeft: "3px" }}>{idScreen != -1 ? <>
                    {(dataIdScreenSelect.cabinetResolutionPXW * dataShare.col)}x{(dataIdScreenSelect.cabinetResolutionPXH * dataShare.row)}px
                  </> : "--"}  </span>
                  <span className="badge right" style={{ marginTop: "10px", marginRight: "3px" }}>{idScreen != -1 ? dataIdScreenSelect.model : "--"}</span>
                </> : ""}

              </div>
              {/* <h3 class="w-full absolute text-center border-b border-white md:leading-[0.1em] text-3xs md:text-sm h3line" ><span class="bg-black py-0 px-1 spanh3 text-white">1.00 m</span></h3> */}
              <div className={`parent-class  ${bgDivWallImage}`} style={{ height: `${(WHData.height) * 100}px`, padding: paddingBox }} id="manageHeight">

                {
                  idScreen != -1 ? setTableStrData() : ""
                }


              </div>


            </div>

            {selectEnv == 1 && WHData.typeOutDoor == 1 ? <>
              <div class="wall"></div></> : ""}
            {/* {selectEnv==1?setTableStrDataBuliding():""} */}

            {
              selectEnv == 0 ? <>
                {
                  WHData.imageIndoor != "" ? <>
                    <div style={{ position: "relative" }} className="bg-info">
                      <div className="text-center">
                        <img src={require(`./../images/${WHData.imageIndoor}`)} fetchPriority="true" alt="Loading Image" className="imageStyling" style={{ position: "absolute", bottom: WHData.imagePossition, left: "30%" }} />
                      </div>

                    </div></> : <></>
                }

              </> : <></>
            }

          </div>
        </div>

      </div>


    </>
  );
}

export default Indoorscreen;
