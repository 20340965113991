import logo from './logo.svg';
import './App.css';
import Homepage from './Component/Homepage';
function App() {
  return (
    <>
    
      <div> 
        <Homepage />
      </div>
    </>
  );
}

export default App;
